import styled from 'styled-components';
import { Container, Col } from 'react-bootstrap';

const Main = styled(Container)`
  margin-top: 1.8rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
`;

const MidBody = styled(Col)`
  padding-right: 2.5rem;
  h1,h2,h3,h4 {
    font-weight: 700;
    margin-bottom: 0.8rem;
    margin-top: 1.8rem;
    color: var(--color-pallate2);
  }
  h1 {
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  h3,h4 {
    font-size: 1.25rem;
    margin-bottom: 0.6rem;
  }
  h3 {
    text-decoration: underline;
  }
  h4 {
    font-size:1.1rem;
  }

  blockquote {
    background: #eee;
    border-left: 10px solid #ccc;
    margin: 1.5em 20px;
    padding: 1em 10px .1em 20px;
  }

  code {
    background-color: var(--color-pallate5);
    color: #ffffff;
    font-weight: bold;
    padding: 0.25rem 0.5rem;;
    border-radius: 0.75rem;
    font-size: 0.75rem;
  }

/* Table Styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1.5rem 0;
    font-size: 1rem;
    min-width: 400px;
    border: 1px solid #ddd;
  }

  thead {
    background-color: var(--color-pallate2);
    color: #ffffff;
    text-align: left;
  }

  th, td {
    padding: 0.75rem;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
/*
  p > a {
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
*/
`;

const RightBody = styled(Col)`
  border-left: 1px solid #ccc;
  @media (max-width: 767px) {
    border-left: none;
  }
`;


export {
  Main,
  MidBody,
  RightBody,
};

